.login-body-wrapper {
	padding-bottom: 100px;
	position: relative;
	min-width: 400px;
}
.login-wrapper {
	position: relative;
	margin: 3em auto 1em auto;
	width: 350px;
	padding: 2.25em 2em 2.5em 2em;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}
.login-logo-wrapper {
	width: 100%;
	text-align: center;
	padding-top: 2px;
	margin: 0 0 2em 0;
}
.login-logo-wrapper .logo-img {
	width: 160px;
	display: block;
	margin: 0 auto 1em auto;
}
.logo-img:hover {
	cursor: pointer;
}
.login-logo-wrapper p {
	display: inline-block;
	vertical-align: top;
	font-size: 0.85em;
	color: white;
}
.login-prompt {
	margin-top: 2em;
	padding: 0 2em;
	line-height: 1.5em;
}
.login-error-wrapper {
	width: 350px;
	margin: 0 auto;
	text-align: center;
}
.google-login-wrapper {
	margin: 2em 0 1em 0;
}
.google-login-btn {
	width: 100%;
	display: block !important;
	border-radius: 25px !important;
	height: 40px;
	-moz-transition: background 0.15s, width 0.15s, margin-left 0.15s, height 0.25s, border-radius 0.25s,
		line-height 0.25s, -moz-transform ease-in-out 0.15s;
	-webkit-transition: background 0.15s, width 0.15s, margin-left 0.15s, height 0.25s, border-radius 0.25s,
		line-height 0.25s, -moz-transform ease-in-out 0.15s;
	-o-transition: background 0.15s, width 0.15s, margin-left 0.15s, height 0.25s, border-radius 0.25s,
		line-height 0.25s, -moz-transform ease-in-out 0.15s;
	transition: background 0.15s, width 0.15s, margin-left 0.15s, height 0.25s, border-radius 0.25s, line-height 0.25s,
		-moz-transform ease-in-out 0.15s;
}
.google-login-btn:hover {
	width: 104%;
	margin-left: -2%;
}
.google-login-btn div {
	display: inline-block;
	vertical-align: top;
	position: relative;
	background: transparent !important;
}
.google-login-btn span {
	line-height: 39px;
	color: #1b1b24;
}
.google-last-login {
	color: #98adbf;
	text-align: center;
	margin-top: 0.7em;
	font-size: 0.8em;
	font-style: italic;
}
