a {
	text-decoration: none;
}
.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
textarea,
input {
	outline: none;
}
button:focus {
	outline: 0;
}
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
	display: block;
}
html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
	font-family: inherit;
	font-weight: inherit;
}
img,
object,
embed {
	max-width: 100%;
}
html {
	overflow-y: scroll;
}
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
del {
	text-decoration: line-through;
}
abbr[title],
dfn[title] {
	border-bottom: 1px dotted #000;
	cursor: help;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
th {
	font-weight: 600;
	vertical-align: bottom;
}
td {
	vertical-align: top;
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}
input,
select {
	vertical-align: middle;
}
pre {
	white-space: pre; /* CSS2 */
	white-space: pre-wrap; /* CSS 2.1 */
	white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
	word-wrap: break-word; /* IE */
}
input[type="radio"] {
	vertical-align: text-bottom;
}
input[type="checkbox"] {
	vertical-align: bottom;
}
.ie7 input[type="checkbox"] {
	vertical-align: baseline;
}
.ie6 input {
	vertical-align: text-bottom;
}
table {
	font-size: inherit;
	font-size: 100%;
}
small {
	font-size: 85%;
}
strong {
	font-weight: 700;
}
td,
td img {
	vertical-align: top;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
pre,
code,
kbd,
samp {
	font-family: "Inconsolata", "Menlo", "Consolas", monospace !important;
	tab-size: 4;
}
.clickable,
label,
summary,
input[type="button"],
input[type="submit"],
input[type="file"],
button {
	cursor: pointer;
}
button,
input,
select,
textarea {
	margin: 0;
}
button,
input[type="button"] {
	width: auto;
	overflow: visible;
}
.ie7 img {
	-ms-interpolation-mode: bicubic;
}
.clearfix:before,
.clearfix:after {
	content: "\0020";
	display: block;
	height: 0;
	overflow: hidden;
}
.clearfix:after {
	clear: both;
}
.clearfix {
	zoom: 1;
}
