/* .admin-wrapper {

} */
.admin-field {
	padding: 1em;
}
.admin-field label{
	display: block;
	width: 100%;
}
.admin-field textarea{
	display: block;
	width: 100%;
	min-height: 10em;
	font-family: monospace;
}